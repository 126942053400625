import SubmitButton from 'components/Button/SubmitButton'
import PageHedding from 'components/PageHedding/PageHedding'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import stamp from 'images/stamp.png'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducks/reducers'
import { setIsLoading } from 'reducks/loading/slice'
import { auth, functions } from 'fb/index'
import { getShopById } from 'reducks/shop/slice'
import axios from 'axios'
import { httpsCallable } from 'firebase/functions'
import { getShopSubscriptionById } from 'reducks/shopSubscriptions/slice'
import { addDays, addMonths, format } from 'date-fns'
import { addYears } from 'date-fns/esm'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

// TODO: Stripeの型定義を用いる
type InvoiceType = {
  amount_due: number
  created: number
  period_end: number
  period_start: number
  lines: InvoiceLineType
  paid: boolean
  subtotal: number
}
type InvoiceLineType = {
  data: InvoiceDataType[]
}
type InvoiceDataType = {
  price: InvoicePriceType
}
type InvoicePriceType = {
  id: String
}
const PlanConfirmTemplate: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const location = useLocation()
  const path = location.pathname
  const [isModal, setIsModal] = useState(false)
  const [selectIndex, setSelectIndex] = useState(0)
  const [cardLast4, setCardLast4] = useState('')
  const [cardExp, setCardExp] = useState('')
  const [invoices, setInvoices] = useState<InvoiceType[]>()
  const [planMessage, setPlanMessage] = useState('')
  const [isBillPayment, setIsBillPayment] = useState(false)
  const [currentPlan, setCurrentPlan] = useState<
    'liteMonth' | 'liteYear' | 'standardMonth'
  >('standardMonth')
  const { staff } = useSelector((state: RootState) => state.staffSlice)
  const handleClosePreview = useCallback(() => {
    setIsModal(false)
  }, [])
  const handleRecipt = useCallback((index: number) => {
    setIsModal(true)
    setSelectIndex(index)
  }, [])
  const dispatchGetShop = useCallback(
    async (shopId: string) => {
      const token = await auth.currentUser?.getIdToken()
      const shop = await dispatch(getShopById({ id: shopId }))
      // カード情報取得
      if (shop.payload.stripe_payment_method_id && staff?.storeId) {
        axios
          .post(
            `${process.env.REACT_APP_FUNCTIONS_URL}/apiRetrieveCard`,
            {
              payment_method_id: shop.payload.stripe_payment_method_id,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
              },
            },
          )
          .then((result) => {
            if (
              !!result.data.card.last4 &&
              !!result.data.card.exp_year &&
              !!shop.payload.stripe_payment_method_id &&
              !!shop.payload.stripe_customer_id
            ) {
              // setCardData(
              //   `**** **** **** ${result.data.card.last4}|${result.data.card.exp_year}/${result.data.card.exp_month}|${shop.payload.stripe_payment_method_id}|${shop.payload.stripe_customer_id}`,
              // )
              setCardLast4(result.data.card.last4)
              setCardExp(
                `${result.data.card.exp_year}/${result.data.card.exp_month}`,
              )
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            dispatch(setIsLoading({ isLoading: false }))
          })

        // 支払い履歴
        const shopSubscription = await dispatch(
          getShopSubscriptionById({
            storeId: staff.storeId,
          }),
        )
        const apiGetStripePaymentIntent = httpsCallable(
          functions,
          'apiGetStripePaymentIntent',
        )
        const apiGetStripeSubscriptionDaseruno = httpsCallable(
          functions,
          'apiGetStripeSubscriptionDaseruno',
        )
        const stripeDaserunoSubscriptionId = shopSubscription.payload
          .stripe_daseruno_subscription_id
          ? shopSubscription.payload.stripe_daseruno_subscription_id
          : ''
        const stripeDaserunoSubscriptionScheduleId: string = shopSubscription
          .payload.stripe_daseruno_subscription_schedule_id
          ? shopSubscription.payload.stripe_daseruno_subscription_schedule_id
          : ''
        const stripeDaserunoOldSubscriptionIds: string[] =
          shopSubscription.payload.stripe_daseruno_old_subscription_ids &&
          shopSubscription.payload.stripe_daseruno_old_subscription_ids.length >
            0
            ? shopSubscription.payload.stripe_daseruno_old_subscription_ids
            : []
        if (stripeDaserunoSubscriptionId) {
          // subscriptionIdがある
          const currentInvoices: InvoiceType[] = []
          apiGetStripePaymentIntent({
            subscriptionId: stripeDaserunoSubscriptionId,
          })
            .then((result: any) => {
              currentInvoices.push(...result.data.message.data)
              if (stripeDaserunoOldSubscriptionIds.length > 0) {
                stripeDaserunoOldSubscriptionIds.forEach(
                  (oldSubscriptionId) => {
                    apiGetStripePaymentIntent({
                      subscriptionId: oldSubscriptionId,
                    })
                      .then((result: any) => {
                        currentInvoices.push(...result.data.message.data)
                        setInvoices(currentInvoices)
                      })
                      .catch((e) => console.log(e))
                      .finally(() =>
                        dispatch(setIsLoading({ isLoading: false })),
                      )
                  },
                )
              } else {
                setInvoices(currentInvoices)
              }
            })
            .catch((e) => console.log(e))
            .finally(() => dispatch(setIsLoading({ isLoading: false })))
          apiGetStripeSubscriptionDaseruno({
            subscriptionId: stripeDaserunoSubscriptionId,
          })
            .then((result: any) => {
              if (
                result.data.items &&
                result.data.items.data &&
                result.data.items.data.length > 0 &&
                result.data.items.data[0].price &&
                result.data.items.data[0].price.id
              ) {
                const plan =
                  result.data.items.data[0].price.id ===
                  process.env.REACT_APP_STRIPE_PRICE_LITE_MONTH_ID
                    ? 'liteMonth'
                    : result.data.items.data[0].price.id ===
                      process.env.REACT_APP_STRIPE_PRICE_LITE_YEAR_ID
                    ? 'liteYear'
                    : [
                        process.env.REACT_APP_STRIPE_PRICE_STANDARD_MONTH_ID,
                        process.env.REACT_APP_STRIPE_PRICE_STANDARD_MONTH_V2_ID,
                      ].includes(result.data.items.data[0].price.id)
                    ? 'standardMonth'
                    : null
                if (plan) {
                  setCurrentPlan(plan)
                }
              }
            })
            .catch((e) => console.log(e))
            .finally(() => dispatch(setIsLoading({ isLoading: false })))
        } else if (stripeDaserunoSubscriptionScheduleId) {
          // スケジュール中
          setPlanMessage('決済処理中のため表示されるまで1日程度お待ちください')
        }
      } else if (staff?.storeId) {
        const shopSubscription = await dispatch(
          getShopSubscriptionById({
            storeId: staff.storeId,
          }),
        )
        const isBillPayment = shopSubscription.payload.is_bill_payment
          ? shopSubscription.payload.is_bill_payment
          : false
        if (isBillPayment) {
          // 請求書払い
          setIsBillPayment(true)
          setPlanMessage('請求書払い')
        }
        dispatch(setIsLoading({ isLoading: false }))
      } else {
        dispatch(setIsLoading({ isLoading: false }))
      }
    },
    [dispatch, staff?.storeId],
  )
  const handleDownload = useCallback(() => {
    const target = document.getElementById('modal-price-card')
    if (target) {
      html2canvas(target, { scale: 3.2 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/svg', 1.0)
        let pdf = new jsPDF()
        pdf.addImage(
          imgData,
          'SVG',
          5,
          10,
          canvas.width / 15,
          canvas.height / 15,
        )
        pdf.save(`daseruno-invoice.pdf`)
        setIsModal(false)
      })
    }
  }, [])
  useEffect(() => {
    if (!staff) {
      navigate('/initload?path=' + path)
      window.scrollTo(0, 0)
      return
    }
    if (staff?.shopId) {
      dispatch(setIsLoading({ isLoading: true }))
      //カード情報を取得
      dispatchGetShop(staff.shopId)
    }
  }, [staff])
  return (
    <>
      <div className={styles.container} style={{ paddingBottom: 80 }}>
        <PageHedding title="マイページ" to="/mypage" />
        <div className={styles.flexColumnCenter}>
          <p className={styles.registerplan_section_title}>
            現在のご利用プランについて
          </p>
          <div style={{ marginTop: 40 }}>
            <div className={styles.plan_confirm_box}>
              <div className={styles.plan_confirm_box_label}>ご利用プラン</div>
              <input
                value={
                  planMessage
                    ? planMessage
                    : currentPlan === 'standardMonth'
                    ? '月々スタンダードプラン'
                    : currentPlan === 'liteYear'
                    ? '年割ライトプラン'
                    : currentPlan === 'liteMonth'
                    ? '月々ライトプラン'
                    : ''
                }
                className={styles.plan_confirm_box_input}
                style={{ width: 400, marginLeft: 24, fontWeight: 'bold' }}
                readOnly
              />
              {!isBillPayment ? (
                <div style={{ marginLeft: 24 }}>
                  {currentPlan === 'standardMonth' ? (
                    <SubmitButton
                      label="変更する"
                      color="gray"
                      size="rectangle_middle"
                      icon="none"
                      onClick={() => {}}
                    />
                  ) : (
                    <SubmitButton
                      label="変更する"
                      color="orange"
                      size="rectangle_middle"
                      icon="none"
                      onClick={() => navigate('/edit_plan')}
                    />
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className={styles.plan_confirm_box} style={{ marginTop: 32 }}>
              <div className={styles.plan_confirm_box_label}>支払方法</div>
              <div
                className={styles.plan_confirm_box_input}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>xxxx xxxx xxxx {cardLast4}</div>
                <div>{cardExp}</div>
              </div>
              <div style={{ marginLeft: 24 }}>
                <SubmitButton
                  label="変更する"
                  color="orange"
                  size="rectangle_middle"
                  icon="none"
                  onClick={() => navigate('/edit_payment')}
                />
              </div>
            </div>
            <div className={styles.plan_confirm_box} style={{ marginTop: 32 }}>
              <div className={styles.plan_confirm_box_label}>次回更新</div>
              <input
                value={
                  invoices && invoices.length > 0
                    ? [9800, 19800, 10780].includes(invoices[0].subtotal)
                      ? format(
                          addMonths(new Date(invoices[0].created * 1000), 1),
                          'yyyy年MM月dd',
                        )
                      : format(
                          addYears(new Date(invoices[0].created * 1000), 1),
                          'yyyy年MM月dd',
                        )
                    : ''
                }
                className={styles.plan_confirm_box_input}
                style={{ width: 400, marginLeft: 24 }}
                readOnly
              />
            </div>
            <p
              className={styles.registerplan_notice_text_left}
              style={{ marginTop: 42 }}
            >
              ※
              次回更新日の1日前までに変更しない場合、自動的に現プランが継続されます。
            </p>
            <p className={styles.registerplan_notice_text_left}>
              ※ ご利用期間中の途中解約はできません。
            </p>
            <p className={styles.registerplan_notice_text_left}>
              ※
              ご解約をご希望される場合は、ご解約の前月の末日までにご担当のダセルーノスタッフまでご連絡ください。
            </p>
          </div>
          <p
            className={styles.registerplan_section_title}
            style={{ marginTop: 80 }}
          >
            支払履歴
          </p>
          <div style={{ marginTop: 40 }}>
            <table className={styles.plan_confirm_table}>
              <tbody>
                <tr className={styles.plan_confirm_table_header}>
                  <th className={styles.plan_confirm_table_th}>日付</th>
                  <th className={styles.plan_confirm_table_th}>ご利用プラン</th>
                  <th className={styles.plan_confirm_table_th}>ご利用期間</th>
                  <th className={styles.plan_confirm_table_th}>金額</th>
                  <th className={styles.plan_confirm_table_th}></th>
                </tr>
                {invoices?.map((invoice, index) => {
                  return invoice.amount_due !== 0 && invoice.paid ? (
                    <tr style={{ paddingLeft: 24 }}>
                      <td className={styles.plan_confirm_table_td}>
                        {format(
                          new Date(invoice.created * 1000),
                          'yyyy年MM月dd',
                        )}
                      </td>
                      <td className={styles.plan_confirm_table_td}>
                        {invoice.lines.data[0].price.id ===
                          process.env
                            .REACT_APP_STRIPE_PRICE_STANDARD_MONTH_ID ||
                        invoice.lines.data[0].price.id ===
                          process.env
                            .REACT_APP_STRIPE_PRICE_STANDARD_MONTH_V2_ID
                          ? '月々スタンダードプラン'
                          : invoice.lines.data[0].price.id ===
                            process.env.REACT_APP_STRIPE_PRICE_LITE_YEAR_ID
                          ? '年割ライトプラン'
                          : invoice.lines.data[0].price.id ===
                            process.env.REACT_APP_STRIPE_PRICE_LITE_MONTH_ID
                          ? '月々ライトプラン'
                          : ''}
                      </td>
                      <td className={styles.plan_confirm_table_td}>
                        {format(
                          new Date(invoice.created * 1000),
                          'yyyy年MM月dd',
                        )}{' '}
                        ~{' '}
                        {invoice.amount_due === 107760
                          ? format(
                              addDays(
                                addYears(new Date(invoice.created * 1000), 1),
                                -1,
                              ),
                              'yyyy年MM月dd',
                            )
                          : format(
                              addDays(
                                addMonths(new Date(invoice.created * 1000), 1),
                                -1,
                              ),
                              'yyyy年MM月dd',
                            )}
                      </td>
                      <td className={styles.plan_confirm_table_td}>
                        {invoice.amount_due.toLocaleString()}
                      </td>
                      <td className={styles.plan_confirm_table_td}>
                        <div
                          className={styles.login_link_container_text}
                          onClick={() => handleRecipt(index)}
                        >
                          領収書
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ''
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isModal && invoices && (
        <div className={styles.modal_price_card}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
            id="modal-price-card"
          >
            <img
              className={styles.plan_confirm_recipt_modal_stamp}
              src={stamp}
              alt="stamp"
            />
            <div className={styles.plan_confirm_recipt_modal}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 2 }}>
                  <div className={styles.plan_confirm_recipt_modal_title}>
                    領収書
                  </div>
                  <div className={styles.plan_confirm_recipt_modal_name}>
                    様
                  </div>
                </div>
                <div style={{ flex: 1, marginLeft: 24 }}>
                  <div
                    className={styles.plan_confirm_recipt_modal_company_name}
                  >
                    株式会社sacri
                  </div>
                  <div>東京都千代田区神田錦町二丁目2番地1</div>
                  {
                    // 2024/12/1以降に発行された領収書には適格事業者番号を印字する
                  }
                  {new Date(invoices[selectIndex].created * 1000).getTime() >=
                  new Date(2024, 11, 1, 0, 0, 0).getTime() ? (
                    <div>登録番号:T9011501025443</div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div style={{ marginTop: 24, marginBottom: 24 }}>
                下記、正に受領いたしました。
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 5 }}>
                  <div
                    className={styles.plan_confirm_recipt_modal_border_header}
                  >
                    {/* No.0000000000 */}
                  </div>
                  <div className={styles.plan_confirm_recipt_modal_border_wrap}>
                    <div className={styles.plan_confirm_recipt_modal_content}>
                      <div className={styles.plan_confirm_recipt_modal_label}>
                        日付
                      </div>
                      <div className={styles.plan_confirm_recipt_modal_value}>
                        {format(
                          new Date(invoices[selectIndex].created * 1000),
                          'yyyy/MM/dd',
                        )}
                      </div>
                    </div>
                    <div className={styles.plan_confirm_recipt_modal_content}>
                      <div className={styles.plan_confirm_recipt_modal_label}>
                        内容
                      </div>
                      <div className={styles.plan_confirm_recipt_modal_value}>
                        {invoices[selectIndex].lines.data[0].price.id ===
                          process.env
                            .REACT_APP_STRIPE_PRICE_STANDARD_MONTH_ID ||
                        invoices[selectIndex].lines.data[0].price.id ===
                          process.env
                            .REACT_APP_STRIPE_PRICE_STANDARD_MONTH_V2_ID
                          ? '月々スタンダードプラン'
                          : invoices[selectIndex].lines.data[0].price.id ===
                            process.env.REACT_APP_STRIPE_PRICE_LITE_YEAR_ID
                          ? '年割ライトプラン'
                          : invoices[selectIndex].lines.data[0].price.id ===
                            process.env.REACT_APP_STRIPE_PRICE_LITE_MONTH_ID
                          ? '月々ライトプラン'
                          : ''}
                      </div>
                    </div>
                    <div className={styles.plan_confirm_recipt_modal_content}>
                      <div className={styles.plan_confirm_recipt_modal_label}>
                        サービス期間
                      </div>
                      <div className={styles.plan_confirm_recipt_modal_value}>
                        {format(
                          new Date(invoices[selectIndex].created * 1000),
                          'yyyy/MM/dd',
                        )}{' '}
                        ~{' '}
                        {[9800, 19800, 10780].includes(
                          invoices[selectIndex].subtotal,
                        )
                          ? format(
                              addDays(
                                addMonths(
                                  new Date(
                                    invoices[selectIndex].created * 1000,
                                  ),
                                  1,
                                ),
                                -1,
                              ),
                              'yyyy/MM/dd',
                            )
                          : format(
                              addDays(
                                addYears(
                                  new Date(
                                    invoices[selectIndex].created * 1000,
                                  ),
                                  1,
                                ),
                                -1,
                              ),
                              'yyyy/MM/dd',
                            )}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: 4, marginLeft: 24 }}>
                  <div
                    className={styles.plan_confirm_recipt_modal_border_header}
                  >
                    合計金額（税込）
                  </div>
                  <div className={styles.plan_confirm_recipt_modal_border_wrap}>
                    <div className={styles.plan_confirm_recipt_modal_price}>
                      ¥{invoices[selectIndex].amount_due.toLocaleString()}
                    </div>
                    {
                      // 2024/12/1以降に発行された領収書には小計を印字する
                    }
                    {new Date(invoices[selectIndex].created * 1000).getTime() >=
                    new Date(2024, 11, 1, 0, 0, 0).getTime() ? (
                      <div
                        className={styles.plan_confirm_recipt_modal_sub_price}
                      >
                        <div
                          className={styles.plan_confirm_recipt_modal_content}
                        >
                          <div
                            className={
                              styles.plan_confirm_recipt_modal_sub_price_title
                            }
                          >
                            小計
                          </div>
                        </div>
                        <div
                          className={styles.plan_confirm_recipt_modal_content}
                        >
                          <div
                            className={
                              styles.plan_confirm_recipt_modal_sub_price_label
                            }
                          >
                            10%対象（税抜）
                          </div>
                          <div
                            className={
                              styles.plan_confirm_recipt_modal_sub_price_value
                            }
                          >
                            ¥
                            {Math.floor(
                              (invoices[selectIndex].amount_due / 11) * 10,
                            ).toLocaleString()}
                          </div>
                          <div
                            className={
                              styles.plan_confirm_recipt_modal_sub_price_label
                            }
                          >
                            消費税
                          </div>
                          <div
                            className={
                              styles.plan_confirm_recipt_modal_sub_price_value
                            }
                          >
                            ¥
                            {Math.floor(
                              invoices[selectIndex].amount_due / 11,
                            ).toLocaleString()}
                          </div>
                        </div>
                        <div
                          className={styles.plan_confirm_recipt_modal_content}
                        >
                          <div
                            className={
                              styles.plan_confirm_recipt_modal_sub_price_label
                            }
                          >
                            8%対象（税抜）
                          </div>
                          <div
                            className={
                              styles.plan_confirm_recipt_modal_sub_price_value
                            }
                          >
                            ¥0
                          </div>
                          <div
                            className={
                              styles.plan_confirm_recipt_modal_sub_price_label
                            }
                          >
                            消費税
                          </div>
                          <div
                            className={
                              styles.plan_confirm_recipt_modal_sub_price_value
                            }
                          >
                            ¥0
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.shopInfoButtonContainer}>
            <SubmitButton
              label="閉じる"
              color="orange_outlined"
              size="xmiddle"
              icon="none"
              onClick={handleClosePreview}
            />
            <div style={{ marginLeft: 32 }}>
              <SubmitButton
                label="ダウンロードする"
                color="orange"
                size="xmiddle"
                icon="none"
                onClick={handleDownload}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PlanConfirmTemplate
